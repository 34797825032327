<template>
  <div class="story-map">
    <div class="map-container">
       <l-map :mapId="mapId" :options="mapOptions" :events="['ready']" @ready="handleMapReady">
         <l-tile-layer v-bind="tileLayer" />

        <template v-if="!loadingStories">
          <l-marker v-for="story in stories"
            :key="story.id"
            :events="['click']"
            @click="handleMarkerClick(story)"
            :latlng="[story.field_latitude, story.field_longitude]"
            :options="{
              id: story.id,
              path: story.path,
              icon: getIcon()
            }"/>
          
          <l-marker v-if="activeStory"
            :latlng="[activeStory.field_latitude, activeStory.field_longitude]"
            :options="{
              id: activeStory.id,
              path: activeStory.path,
              icon: getActiveIcon()
            }"/>
        </template>
       </l-map>
    </div>

    <div class="story-map__overlay"></div>

    <v-layout column class="story-map__content">
      <v-flex xs12 class="story-map__content-header">
        <h1 class="mt-0">Stories from <br />the West</h1>
      </v-flex>

      <v-flex xs12 class="story-map__inner-content">
        <v-progress-circular 
          v-if="loadingStories"
          :size="60"
          indeterminate>
        </v-progress-circular>
        <v-expansion-panel v-else
          v-model="openPanel" 
          class="elevation-0"
          ref="expansionParent">
          <v-expansion-panel-content 
            v-for="story in stories" 
            expand-icon="arrow_forward" 
            :key="story.id">
            <header slot="header">
              <h4>{{story.title}}</h4>
              <span class="story-map__inner-content__summary">{{story.body.summary}}</span>
            </header>
            
            <div class="story-map__inner-content-body">
              <div v-html="story.body.value"></div>

              <router-link 
                v-if="story.field_stack"
                :to="{
                  name: 'make',
                  params: {
                    id: story.field_stack
                  }
                }"
                class="story-map__image--with-icon">
                <v-img
                  :src="`${host}${story.field_image.uri.url}`"
                  aspect-ratio="2"
                  max-width="450"/>
                <v-icon color="secondary" size="60">play_circle_outline</v-icon>
              </router-link>

              <div v-else class="story-map__image">
                <v-img
                  :src="`${host}${story.field_image.uri.url}`"
                  aspect-ratio="2"
                  class="text-xs-center"
                  max-width="450"/>
              </div>

              <div 
                v-if="(story.field_cbo && story.field_cbo.title) || story.field_made_by"
                class="story-map__made-by">
                <div class="story-map__made-by-title">A Story Made By</div>
                <router-link
                  v-if="story.field_cbo && story.field_cbo.title"
                  :to="{
                    path: `/connect#${story.field_cbo.title}`
                  }"
                >
                  {{ story.field_cbo.title }}
                </router-link>
                <a
                  v-else-if="story.field_made_by_link"
                  :href="story.field_made_by_link"
                  target="_blank"
                  rel="noopener"
                >
                  {{ story.field_made_by }}
                </a>
                <div v-else>{{ story.field_made_by }}</div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { mergeDefaultLeafletIcon } from '@/utils/general'
  import VueScrollTo from 'vue-scrollto'

  import { mapActions } from 'vuex'
  import {
    actions as contentActions,
    getters as contentGetters
  } from '@/mixins/connectors/content'
  import {
    allStories
  } from '@/api/utils/queries'

  const {
    VUE_APP_DRUPAL_API
  } = process.env

  export default {
    name: 'story-map',

    mixins: [contentActions, contentGetters],

    watch: {
      openPanel(index) {
        if (![null, undefined, -1].includes(index)) {
          if (this.stories[index].path === this.$route.params.path) return

          this.$router.push({
            params: {
              path: this.stories[index].path
            }
          })
            .catch(e => {
              console.log('ERROR', e)
            })
        } else {
          const {
            params: {
              path
            } = {}
          } = this.$route

          if (path) { this.$router.push({ name: 'storyMap' }) }
        }
      },
      '$route.params.path': {
        handler (path) {
          this.openPanel = this.getStoryIndexByPath(path)
          this.drawMap()
        }
      }
    },

    data(){
      return {
        host: VUE_APP_DRUPAL_API,
        loadingStories: true,
        mapId: 'storymap',
        tileLayer: {
          urlTemplate: 'https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}?access_token={accessToken}',
          options: {
            attribution: 'Tiles &copy; Esri',
            accessToken: 'pk.eyJ1IjoibWF0dC1lLWtpbmciLCJhIjoiY2l6eWtwaGhxMDA2MTJxbXlvY2RuM2h5byJ9.50i1OwaHIrEI9nlAzl-dIQ',
            label: 'Default'
          }
        },
        mapOptions: {
          zoom: 5,
          maxZoom: 8,
          minZoom: 2,
          center: [38.880890, -122.930632],
          zoomControl: false
        },
        mapObj: null,
        openPanel: -1,
        largeBoundOptions: { 
          paddingTopLeft: [this.getLeftHalf(), 300],
          paddingBottomRight: [0, 0],
          maxZoom: 5
        },
        smallBoundOptions: {
          paddingTopLeft: [0, 200],
          paddingBottomRight: [0, this.getBottomRight()],
          maxZoom: 3
        },
        xsBoundOptions: {
          paddingTopLeft: [0, 50],
          paddingBottomRight: [0, this.getBottomRight()],
          maxZoom: 2
        }
      }
    },

    computed: {
      activeStory() {
        return this.stories.find(s => s.path === this.$route.params.path)
      },
      stories() {
        return this.getContentTypeByType('story')
      }
    },

    methods:{
      ...mapActions(['generalEvent']),
      getLeftHalf() { return ((window.innerWidth * .5)) },
      getBottomRight() { return ((window.innerHeight * .5 )) },
      drawMap() {
        if (this.activeStory) {
          const { path } = this.activeStory
          const activeStoryIndex = this.getStoryIndexByPath(path)
          this.setDetailMapView()
          this.setStoryScroll(activeStoryIndex)

          let { title } = this.stories[activeStoryIndex] || {}

          this.generalEvent({
            category: 'stories',
            action: 'open',
            label: `${title}`
          })
        } else {
          this.setDefaultMapView()
          this.setStoryScroll(0)
        }
      },
      setStoryScroll(index = 0) {
        setTimeout(() => {
          // https://github.com/rigor789/vue-scrollto
          var options = {
            container: '.story-map__inner-content',
            easing: 'ease-in',
            offset: 0,
            force: true,
            cancelable: true,
            x: false,
            y: true
          }

          // destructuring this.$refs to get expansion panel child by index
          // the child is passed the "element" to scroll to
          const {
            expansionParent: {
              $children: {
                [index]: childNode = {}
              }
            } = {}
          } = this.$refs

          VueScrollTo.scrollTo(childNode.$el, 500, options)
        }, 500)
      },
      getIcon() {
        return mergeDefaultLeafletIcon({
          iconUrl: '/img/grey-marker.png'
        })
      },
      getActiveIcon() {
        return mergeDefaultLeafletIcon({
          iconUrl: '/img/orange-marker.png'
        })
      },
      handleMarkerClick({ path }) {
        this.$router.push({
          params: { path }
        })
      },
      async handleMapReady(){
        this.mapObj = this.$store.getters.getMap(this.mapId)

        this.loadingStories = true

        try {
          await this.fetchContentType({ type: 'story', filter: allStories })

          const {
            params: {
              path: storyPath = ''
            } = {}
          } = this.$route

          this.openPanel = this.getStoryIndexByPath(storyPath)
          this.drawMap()
        } catch (e) {
          throw e
        } finally {
          this.loadingStories = false
        }
      },
      getStoryIndexByPath(path) {
        return this.stories.findIndex((story) => {
          return story.path === path
        });
      },
      setDefaultMapView() {
        let options = this.largeBoundOptions

        if (this.$vuetify.breakpoint.smAndDown) {
          options = Object.assign({}, options, this.smallBoundOptions)
        } else if (this.$vuetify.breakpoint.xs) {
          options = Object.assign({}, options, this.xsBoundOptions)
        }

        this.mapObj.fitBounds([this.mapOptions.center, this.mapOptions.center], options)
      },
      setDetailMapView() {
        let options = {
          paddingTopLeft: [this.getLeftHalf() - 100, 0],
          paddingBottomRight: [0, 0],
          maxZoom: this.mapOptions.maxZoom
        }

        if (this.$vuetify.breakpoint.smAndDown) {
          options = Object.assign({}, options, {
            paddingTopLeft: [0, 0],
            paddingBottomRight: [0, this.getBottomRight()],
          })
        } else if (this.$vuetify.breakpoint.xs) {
          options = Object.assign({}, options, {
            paddingTopLeft: [0, 100],
            paddingBottomRight: [0, this.getBottomRight() + 50],
          })
        }

        const coords = [
          [this.activeStory.field_latitude, this.activeStory.field_longitude],
          [this.activeStory.field_latitude, this.activeStory.field_longitude]
        ]

        this.mapObj.fitBounds(coords, options)
      }
    }
  }
</script>

<style lang="stylus">
  .west-is-burning--page-storyMap
    height: 100%
  .story-map__content
    z-index: 3
    position: absolute
    left: 10vw
    max-width: 475px
    top: 0
    height: 100vh
    height: -webkit-fill-available
    overflow: hidden
  
  .story-map__image
    position: relative
    display: flex
    justify-content: center
    align-items: center

  .story-map__image--with-icon
    position: relative
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer

    .v-icon
      position: absolute

  .story-map__content-header
    height: 40vh
    display: flex
    flex-direction: column
    justify-content: flex-end

  .story-map__inner-content
    margin-top: 30px
    flex-grow: 1
    overflow: auto
    -webkit-overflow-scrolling: touch

    /* for Firefox */
    min-height: 0;

  .story-map__inner-content__summary
    padding-right: 15px
    display: inline-block
    line-height: 16px

  .story-map__overlay
    position: fixed
    width: 50vw
    height: 100%
    top: 0
    left: 0
    pointer-events: none
    opacity: 1

  .theme--light
    &.v-expansion-panel      
      .v-expansion-panel__container
        .v-expansion-panel__header
          h4
            text-transform: unset
            font-size: 14px
            margin-bottom: 2px

        .story-map__inner-content-body
          padding: 0px 20px 20px
        
          p
            font-size: 14px

  .map-container
    height: 100vh
    min-height: -webkit-fill-available
    width: 100%
    margin: 0
    position: fixed
    left: 0
    top: 0
    right: 0 
    bottom: 0
    z-index: 0
      

  .story-map__made-by
    .story-map__made-by-title
      text-transform: uppercase
      font-weight: bold
      color: $color-middle-grey
      margin-top: 20px
    a
      color: white

  @media(min-width: $md-breakpoint)
    .story-map__content
      margin-top: -64px
    
  @media(max-width: $md-breakpoint)
    .story-map__content
      max-width: 100%
      left: 0
      padding: 0px 15px
      position: fixed
      pointer-events: none

    .story-map__inner-content
      pointer-events: all
      margin-top: 0

    .story-map__content-header
      height: 55vh

    .story-map__overlay
      width: 100vw
      height: 70vh
      bottom: 0
      top: auto
</style>