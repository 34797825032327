var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"story-map"},[_c('div',{staticClass:"map-container"},[_c('l-map',{attrs:{"mapId":_vm.mapId,"options":_vm.mapOptions,"events":['ready']},on:{"ready":_vm.handleMapReady}},[_c('l-tile-layer',_vm._b({},'l-tile-layer',_vm.tileLayer,false)),(!_vm.loadingStories)?[_vm._l((_vm.stories),function(story){return _c('l-marker',{key:story.id,attrs:{"events":['click'],"latlng":[story.field_latitude, story.field_longitude],"options":{
              id: story.id,
              path: story.path,
              icon: _vm.getIcon()
            }},on:{"click":function($event){return _vm.handleMarkerClick(story)}}})}),(_vm.activeStory)?_c('l-marker',{attrs:{"latlng":[_vm.activeStory.field_latitude, _vm.activeStory.field_longitude],"options":{
              id: _vm.activeStory.id,
              path: _vm.activeStory.path,
              icon: _vm.getActiveIcon()
            }}}):_vm._e()]:_vm._e()],2)],1),_c('div',{staticClass:"story-map__overlay"}),_c('v-layout',{staticClass:"story-map__content",attrs:{"column":""}},[_c('v-flex',{staticClass:"story-map__content-header",attrs:{"xs12":""}},[_c('h1',{staticClass:"mt-0"},[_vm._v("Stories from "),_c('br'),_vm._v("the West")])]),_c('v-flex',{staticClass:"story-map__inner-content",attrs:{"xs12":""}},[(_vm.loadingStories)?_c('v-progress-circular',{attrs:{"size":60,"indeterminate":""}}):_c('v-expansion-panel',{ref:"expansionParent",staticClass:"elevation-0",model:{value:(_vm.openPanel),callback:function ($$v) {_vm.openPanel=$$v},expression:"openPanel"}},_vm._l((_vm.stories),function(story){return _c('v-expansion-panel-content',{key:story.id,attrs:{"expand-icon":"arrow_forward"}},[_c('header',{attrs:{"slot":"header"},slot:"header"},[_c('h4',[_vm._v(_vm._s(story.title))]),_c('span',{staticClass:"story-map__inner-content__summary"},[_vm._v(_vm._s(story.body.summary))])]),_c('div',{staticClass:"story-map__inner-content-body"},[_c('div',{domProps:{"innerHTML":_vm._s(story.body.value)}}),(story.field_stack)?_c('router-link',{staticClass:"story-map__image--with-icon",attrs:{"to":{
                  name: 'make',
                  params: {
                    id: story.field_stack
                  }
                }}},[_c('v-img',{attrs:{"src":`${_vm.host}${story.field_image.uri.url}`,"aspect-ratio":"2","max-width":"450"}}),_c('v-icon',{attrs:{"color":"secondary","size":"60"}},[_vm._v("play_circle_outline")])],1):_c('div',{staticClass:"story-map__image"},[_c('v-img',{staticClass:"text-xs-center",attrs:{"src":`${_vm.host}${story.field_image.uri.url}`,"aspect-ratio":"2","max-width":"450"}})],1),((story.field_cbo && story.field_cbo.title) || story.field_made_by)?_c('div',{staticClass:"story-map__made-by"},[_c('div',{staticClass:"story-map__made-by-title"},[_vm._v("A Story Made By")]),(story.field_cbo && story.field_cbo.title)?_c('router-link',{attrs:{"to":{
                    path: `/connect#${story.field_cbo.title}`
                  }}},[_vm._v("\n                  "+_vm._s(story.field_cbo.title)+"\n                ")]):(story.field_made_by_link)?_c('a',{attrs:{"href":story.field_made_by_link,"target":"_blank","rel":"noopener"}},[_vm._v("\n                  "+_vm._s(story.field_made_by)+"\n                ")]):_c('div',[_vm._v(_vm._s(story.field_made_by))])],1):_vm._e()],1)])}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }